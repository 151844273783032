<template>
  <div>
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom">
          <b-card-header class="flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">Liste des rôles avec permissions</h3>
            </div>
          </b-card-header>
          <b-card-body>
            <!--begin: Datatable-->
            <b-form>
              <b-row>
                <b-col md="6">
                  <b-form-select
                    style="width: 50px"
                    v-model="perPage"
                    :options="options"
                    size="sm"
                  ></b-form-select>
                </b-col>
                <b-col md="6">
                  <b-form-input
                    class="float-right mb-2"
                    style="width: 200px;"
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    placeholder="Rechercher"
                  >
                  </b-form-input>
                </b-col>
              </b-row>
            </b-form>
            <b-row>
              <b-col>
                <b-table
                  bordered
                  hover
                  outlined
                  :items="roles"
                  :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :fields="fields"
                  responsive="sm"
                >
                  <template #cell(permissions)="data">
                    <ul class="text-info">
                      <li
                        class="list-unstyled"
                        v-for="permission in data.item.permissions"
                        :key="permission.id"
                      >
                        <b class="badge badge-primary">{{ permission.slug }}</b>
                      </li>
                    </ul>
                  </template>

                  <template v-slot:cell(actions)="data">
                    <router-link
                      class="btn btn-warning btn-sm mr-2"
                      :to="{
                        name: 'role.permission.attach',
                        params: { id: data.item.id }
                      }"
                    >
                      <i class="fas fa-edit"></i
                    ></router-link>
                  </template>
                </b-table>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  :fields="fields"
                  aria-controls="my-table"
                  class="float-right mt-2"
                ></b-pagination>
              </b-col>
            </b-row>
            <!--end: Datatable-->
          </b-card-body>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "Index",
  data() {
    return {
      create: "/auth/role/create",
      source: "role",
      resource: "role",
      roles: [],
      // DataTable option
      filter: "",
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "id",
          label: "#"
        },
        {
          key: "name",
          label: "Role"
        },
        {
          key: "permissions",
          label: "Permissions"
        },
        "Actions"
      ],
      options: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" }
      ]
    };
  },
  beforeMount() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let vm = this;
      ApiService.get(this.source).then(function(response) {
        vm.roles = response.data.roles;
      });
    }
  },
  computed: {
    rows() {
      return this.roles.length;
    }
  }
};
</script>

<style scoped></style>
